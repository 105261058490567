import styled from "styled-components";
import {device} from "../../../config/theme";

export const Wrapper = styled.div`
  overflow: visible;
  
  .swiper-slide{
        //width: 350px;
    @media(${device.laptop}){
      padding-bottom: 30;
    }
    padding-bottom: 40px;
    padding-right: 5px;
  }
  
  .swiper-pagination-bullet-active{
    background-color: ${props => props.theme.palette.button.primary.background};
  }
`;

export const ChildWrapper = styled.div`
  overflow: visible;
  
  .swiper-slide{
        //width: 350px;
    @media(${device.laptop}){
      padding-bottom: 30;
    }
    padding-bottom: 40px;
    padding-right: 5px;
  }
  
  .swiper-pagination-bullet-active{
    background-color: ${props => props.theme.palette.button.primary.background};
  }

@font-face {
  font-family: 'Dana';
  src: url('https://static.filmnet.ir/dl/fonts/dana/Dana-FaNum-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Dana';
  src: url('https://static.filmnet.ir/dl/fonts/dana/Dana-FaNum-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: bold;
}

@font-face {
  font-family: 'Dana';
  src: url('https://static.filmnet.ir/dl/fonts/dana/Dana-FaNum-DemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: demiBold;
}

@font-face {
  font-family: 'Dana';
  src: url('https://static.filmnet.ir/dl/fonts/dana/Dana-FaNum-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: extraBold;
}

@font-face {
  font-family: 'Dana';
  src: url('https://static.filmnet.ir/dl/fonts/dana/Dana-FaNum-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: extraLight;
}

@font-face {
  font-family: 'Dana';
  src: url('https://static.filmnet.ir/dl/fonts/dana/Dana-FaNum-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: medium;
}

@font-face {
  font-family: 'Dana';
  src: url('https://static.filmnet.ir/dl/fonts/dana/Dana-FaNum-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: reqular;
}

@font-face {
  font-family: 'Dana';
  src: url('https://static.filmnet.ir/dl/fonts/dana/Dana-FaNum-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: thin;
}

@font-face {
  font-family: 'Dana';
  src: url('https://static.filmnet.ir/dl/fonts/dana/Dana-FaNum-UltraBold.ttf') format('truetype');
  font-weight: 900;
  font-style: ultraBold;
}

* {
box-sizing: border-box;
direction: rtl;
font-family: 'Dana';
position: relative;
margin: 0;
padding: 0;
}

html,
body {
height: 100%;
background-color: #FCFCFC;
box-sizing: border-box;
}

*,
*:before,
*:after {
box-sizing: inherit;
}

h1 {
font-size: 30px;
font-weight: 600;
}

button {
font-size: 16px;
font-weight: 500;
}

p {
font-size: 16px;
font-weight: 600;
}

.skeleton {
--bg: #e1e1e1;
--glare-bg: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, 0.4), transparent);
position: relative;
background-color: var(--bg);
overflow: hidden;
}

.skeleton::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: var(--glare-bg);
    transform: translateX(-100%);
    animation: skeleton-glare 1.5s infinite ease-out;
    z-index: 1;
}

.card__text {
font-size: 1rem;
height: 2em;
border-radius: 20px;
width: 100%;
margin-top: 1em;
}

@keyframes skeleton-glare {
to {
    transform: translateX(100%)
}
}

.hide {
display: none
}

.show {
display: block
}



.back_close {
border: none;
background: transparent;
border-radius: 8px;
cursor: pointer
}

.back_close:hover {
    background: #EBEBEB;
}

.container {
width: 100%;
display: flex;
align-items: center;
flex-direction: column;
}

.header-fixed {
padding: 30px 50px;
height: 80px;
box-sizing: border-box;
-webkit-transition: top 0.3s;
transition: top 0.3s;
}

.header-fixed > nav {
    display: flex;
}

.logo {
margin-left: auto;
}



.box {
width: 50%;
height: auto;
background: #FAFAFA;
box-shadow: 0px 0px 4px rgba(28, 28, 28, 0.25);
border-radius: 8px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
align-items: inherit;
transition: all 0.5s ease;
padding: 32px;
}

.cancel-message {
text-align: center !important;
}


.hidden {
display: none;
}

.bank-logo {
max-width: 64px;
height: auto;
}

.page-title {
font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 46px;
display: flex;
align-items: center;
text-align: center;
margin-bottom: 24px;
}

.info {
background: #EBEBEB;
border-radius: 4px;
padding: 8px;
font-size: 14px;
font-weight: 400;
line-height: 28px;
letter-spacing: 0em;
text-align: right;
width: 100%;
}

.subtitle {
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 32px;
display: flex;
align-items: center;
text-align: right;
color: #1C1C1C;
text-align: right;
width: 100%;
margin-top: 16px;
}

.container-cards {
list-style: none;
margin: 0;
padding: 0;
align-items: center;
}

.item {
padding: 16px;
/* width: 13.5%; */
color: white;
font-weight: bold;
text-align: center;
color: #1C1C1C;
cursor: pointer;
border-radius: 4px;
}

.item:hover,
.active-item {
    background: #D9D9D9;
}

.input-error {
border: 2px solid #E03C4A;
}


.server-error-msg {
font-style: normal;
font-weight: 300;
font-size: 12px;
line-height: 18px;
display: flex;
align-items: center;
text-align: right;
color: #E03C4A;
margin-top: 8px;
}

.server-error-msg > svg {
    margin-left: 8px;
}

/*inline-block*/
.inline-b {
width: 100%;
margin: 0 auto;
}

.inline-b-item {
display: inline-block;
}

.flex-column {
display: flex;
flex-direction: column;
align-items: center;
height: 100%;
}

.card > span {
padding-top: 8px;
padding-right: 8px;
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 24px;
display: flex;
align-items: center;
text-align: right;
}

.form-group {
width: 100%;
margin-top: 16px;
}

.bank-logo-image {
max-width: 64px;
background-color: #FAFAFA;
}

.custom-input {
border: 2px solid #8F8F8F;
border-radius: 4px;
height: 40px;
width: 100%;
padding-left: 12px;
padding-right: 12px;
font-size: 12px;
font-weight: 500;
}

.error-input {
border: 2px solid #F54768 !important;
}

.error-input:hover,
.error-input:active,
.error-input:focus,
.error-input:focus-visible {
    border: 2px solid #F54768 !important;
    outline: none;
}

.custom-input:hover,
.custom-input:active,
.custom-input:focus {
border: 2px solid #1C1C1C;
outline: none;
}

.checkbox-wrapper {
display: flex;
margin-top: 16px;
}

.checkbox-group {
display: block;
margin-left: 8px;
width: 16px;
}

.checkbox-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.checkbox-group label {
    position: relative;
    cursor: pointer;
}

    .checkbox-group label:before {
        content: '';
        -webkit-appearance: none;
        background-color: transparent;
        border: 1px solid #8F8F8F;
        border-radius: 4px;
        padding: 8px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
    }

.checkbox-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    width: 6px;
    height: 14px;
    border: 1px solid #FAFAFA;
    border-radius: 4px;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    right: 6px;
}

.checkbox-group input:checked + label:before {
    background-color: #1C1C1C;
}

.small {
font-weight: 400;
font-size: 12px;
line-height: 24px;
color: #696969;
}

a {
color: #0C85E9;
text-decoration-line: underline;
font-weight: 500;
}

.submit-btn {
width: 100%;
}

.submit-btn,
.home-btn {
background: #D32B4E;
border-radius: 8px;
color: #FFFFFF;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
padding-top: 8px;
padding-bottom: 8px;
border: 0;
margin-top: 56px;
margin-bottom: 0;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
height: 40px;
text-decoration: none;
}

.home-btn {
width: 320px;
text-decoration: none;
}

.submit-btn:disabled,
.home-btn:disabled {
    background: #E3E3E3;
    color: #8F8F8F;
    cursor: not-allowed;
}

.submit-btn:hover:enabled,
.home-btn:hover:enabled {
    background: #A8233C;
}

.loader {
border: 2px solid #f3f3f3;
border-radius: 50%;
border-top: 2px solid #1C1C1C;
width: 20px;
height: 20px;
-webkit-animation: spin 2s linear infinite;
/* Safari */
animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
0% {
    -webkit-transform: rotate(0deg);
}

100% {
    -webkit-transform: rotate(360deg);
}
}

.back_btn-mobile {
display: none;
}

.back-btn-web {
margin-left: auto;
background: #EDEDED;
box-shadow: 0px 0px 4px rgba(28, 28, 28, 0.25);
border-radius: 8px;
text-decoration: none;
padding: 8px 16px;
color: #1C1C1C;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
cursor: pointer;
margin-top: 16px;
margin-bottom: 32px;
}

.back-btn-web:hover,
.cancel-btn:hover,
.cancel-debit:hover,
.back-debit:hover {
    background: #D9D9D9;
    border-radius: 8px;
}

.back-btn-web > svg {
    margin-left: 8px;
}

.top-wrapper {
background: transparent !important;
padding: 0 !important;
box-shadow: none !important;
border-radius: 8px;
}

.msg-box {
width: 584px;
height: auto;
background: #FAFAFA;
box-shadow: 0px 0px 4px rgba(28, 28, 28, 0.25);
border-radius: 8px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
align-items: inherit;
transition: all 0.5s ease;
padding: 32px 24px;
}

.callback-box {
margin-top: 72px;
}


.msg-title {
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 28px;
text-align: center;
color: #1C1C1C;
width: 100%;
margin-top: 24px;
}

.msg-subtitle {
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 28px;
text-align: justify;
margin-top: 24px;
width: 100%;
}

/* .success */

.result-group {
display: flex;
flex-direction: column;
width: 100%;
margin-top: 24px;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 28px;
}

.result-wrapper {
margin-top: 24px;
}

.text-center {
text-align: center
}

#error {
margin-top: 8px;
}

.error-msg {
color: #F54768;
font-weight: 400;
font-size: 12px;
}

.error-msg > svg {
    padding-top: 2px;
}

.success {
color: #008051;
margin-right: 8px;
font-weight: 500;
font-size: 14px;
}

.result {
margin-top: 8px;
}

/* active modal */
.cancel-btn {
background: #E3E3E3;
border-radius: 8px;
color: #1C1C1C;
width: 100%;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
padding-top: 8px;
padding-bottom: 8px;
border: 0;
margin-top: 48px;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
width: 320px;
text-decoration: none;
}

.ebcf_modal {
display: none;
/* Hidden by default */
position: fixed;
/* Stay in place */
z-index: 1;
/* Sit on top */
padding-top: 100px;
/* Location of the box */
left: 0;
top: 0;
width: 100%;
/* Full width */
height: 100%;
/* Full height */
overflow: auto;
/* Enable scroll if needed */
background-color: rgb(229, 229, 229, 1);
/* Fallback color */
background-color: rgba(229, 229, 229, 0.8);
/* Black w/ opacity */
}

/* Modal Content */
.ebcf_modal-content {
background: #FAFAFA;
box-shadow: 0px 0px 4px rgba(28, 28, 28, 0.25);
border-radius: 8px;
margin: auto;
padding: 16px;
top: 0;
bottom: 0;
margin: auto;
position: absolute;
left: 0;
right: 0;
height: auto;
height: 280px;
width: 456px;
}

/* The Close Button */
.ebcf_close {
border: 0;
background-color: transparent;
height: 16px;
margin-left: auto;
}

.ebcf_close:hover,
.ebcf_close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.modal-body {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}

.modal-title {
width: 100%;
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 28px;
text-align: center;
color: #1C1C1C;
margin-top: 16px;
}

.modal-subtitle {
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 32px;
display: flex;
align-items: center;
text-align: center;
color: #1C1C1C;
margin-top: 32px;
}

.back-debit,
.cancel-debit {
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
color: #1C1C1C;
text-decoration: none;
width: 156px;
padding: 8px;
text-align: center;
}

.modal-footer {
display: flex;
margin-top: 56px;
margin-bottom: 32px;
}

.cancel-debit {
background: #E3E3E3;
border-radius: 8px;
margin-left: 32px;
}

.main-error-box {
margin-top: 48px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}

@media (max-width:960px) {
.cancel-btn {
    width: 265px;
}

.ebcf_modal-content {
    width: 80%;
    height: 260px;
}

.modal-title {
    margin-top: 8px;
    font-size: 14px;
}

.modal-subtitle {
    margin-top: 16px;
}

.modal-footer {
    margin-top: 32px;
}

.back-debit,
.cancel-debit {
    font-size: 12px;
    width: 100px;
}


.back_btn-mobile {
    display: block;
}

.back-btn-web {
    display: none;
}

.container {
    padding: 16px;
}

.box,
.msg-box {
    width: 100%;
    padding: 16px;
}


.page-title {
    font-size: 18px;
}

.header-fixed {
    padding: 20px 16px;
    margin-bottom: 10px;
}

.info {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

.subtitle,
.msg-subtitle {
    font-size: 14px;
}

.item {
    padding: 8px;
    width: 90px;
}

.card > svg {
    width: 100%;
}

.card > span {
    font-size: 10px;
    line-height: 16px;
}

.custom-input {
    padding-left: 12px;
    padding-right: 12px;
}

.submit-btn,
.home-btn {
    font-size: 14px;
    margin-bottom: 16px;
    height: 40px;
}

.home-btn {
    width: 265px;
}

.small,
.server-error-msg,
.error-msg {
    font-size: 12px;
}

.checkbox-wrapper {
    margin-top: 16px;
}

.mobile-text {
    margin-top: 24px;
}

.bank-logo-image {
    max-width: 40px;
}

.logo {
    margin-right: auto;
}

.msg-title {
    font-size: 14px;
}

.main-error-box svg {
    height: 100%;
}
}
`;