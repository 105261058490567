import styled from "styled-components";

export const Wrapper = styled.div`

  .Toastify__toast-container {
    padding: 0;
    width: 100%;
    max-width: 400px;
    right: 50%;
    transform: translateX(50%);
  }
  .Toastify__toast-container--bottom-center{
    bottom: 0;
  }

  .toast {
    width: 100%;
    margin: auto;
    display: flex;
    padding: 0 1rem;
    align-items: center;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;

    &.Toastify__toast--success {
      background-color: ${props => props.theme.palette.toast.success.background};
      color: ${props => props.theme.palette.toast.success.color};
    }
    &.Toastify__toast--error {
      background-color: ${props => props.theme.palette.toast.error.background};
      color: ${props => props.theme.palette.toast.error.color};
    }
  }
`;