import React, {useContext} from 'react';
import Typography from "../typography/Typography";
import * as Styled from "./styles";
import {AuthContext} from "../../providers/AuthProvider";
import MobileIcon from '../icons/MobileIcon';

const UserProfileStatus = () => {
    const {user} = useContext(AuthContext);

    return (
        <Styled.Wrapper>
            <MobileIcon className={"subscription-icon"}/>
            &nbsp;
            <Typography variant={"bodySmall"}>حساب کاربری:</Typography>
            &nbsp;
            <span className={"pro-label"}>{user.phoneNumber ?? user.email}</span>
        </Styled.Wrapper>
    );
};

export default UserProfileStatus;