import {BrowserRouter, Route, Routes, useSearchParams} from "react-router-dom";
import GlobalStyle from "./styles/global";
import {useContext} from "react";
import {AuthContext} from "./providers/AuthProvider"
import ProtectedRoute from "./components/ProtectedRoute";
import Shop from "./pages/shop/Shop";
import Login from "./pages/login/login";
import Loading from "./components/loading/Loading";
import 'react-toastify/dist/ReactToastify.css';
import Toast from "./components/toast/Toast";
import AppProvider from "./providers/AppProvider";
import storage from "./config/storage";
import Bank from "./pages/shop/Bank";

function App() {
    return (
        <BrowserRouter>
            <AppProvider>
                <GlobalStyle/>
                <AppRoutes/>
                <Toast/>
            </AppProvider>
        </BrowserRouter>
    );
}

const AppRoutes = () => {
    const { user, isLoading } = useContext(AuthContext);
    
    const [searchParams] = useSearchParams();
    let version = parseInt(searchParams.get("version")); //ph=09120232717 if storage.phone != ph { logout }
    let ph = searchParams.get("ph");
    let token = searchParams.get("token");
    let market = searchParams.get("market");

    if (market != null) {
        storage.setMarket(market);
    }

    if (token != null) {
        storage.setToken(token);
    }

    if (ph != null) {
        storage.setIdentity(ph);
    }
    
    if (version > storage.getVersion()) {
        storage.setVersion(version); 
    } else {
        storage.setVersion(storage.getVersion() || 2); 
    }

    if (isLoading) {
        return <Loading/>
    }

    return (
        <Routes>
            <Route element={<ProtectedRoute isAllowed={!!user} redirectPath={"/login"}/>}>
                <Route path="/" element={<Shop/>}/>
            </Route>
            <Route element={<ProtectedRoute isAllowed={!user} redirectPath={"/"}/>}>
                <Route path="/login" element={<Login/>}/>
            </Route>
            <Route>
                <Route path="/banks" element={<Bank/>}/>
                <Route path="/banks/:id" element={<Bank/>}/>
            </Route>
        </Routes>
    )
}


export default App;
