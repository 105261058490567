import React, {useEffect, useState} from 'react';
import * as Styled from './styles';

const ResendOtpButton = ({onResend,time=60}) => {

    const [remainingTime, setRemainingTime] = useState(time);

    useEffect(() => {
        const timeout = setInterval(() => {
            setRemainingTime(time => time - 1);
        },1000);
        return () => clearTimeout(timeout);
    }, []);

    const onClick = () => {
        onResend();
        setRemainingTime(time);
    }

    return (
        <Styled.Wrapper>{
            remainingTime < 1 ? <Styled.ResendButton onClick={onClick}>ارسال مجدد کد</Styled.ResendButton> : `${remainingTime} ثانیه تا ارسال مجدد کد`
        }</Styled.Wrapper>
    );
};

export default ResendOtpButton;