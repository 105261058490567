import axios from 'axios';
import {SERVER_URL} from "./constants";
import storage from "./storage";
import authService from "../services/authService";

let refreshing_token = null;
const api = axios.create({
    baseURL: SERVER_URL,
    headers: {'Content-Type': 'application/json', 'x-deviceType': 'PWA', 'x-versionCode': 1.0,'x-market':'PWA'},
    responseType: 'json',
    retry:true
});

api.interceptors.request.use((config) => {
    return {
        ...config,
        headers: {
            'Authorization': storage.getToken(),
            ...config.headers,
        }
    };
});

api.interceptors.response.use((response) => {
    return transformResponse(response)
}, async (error) => {
    const config = error.config;
    if ([401, 403].includes(error.response.status) && !config._retried && config.retry) {
        config._retried = true;
        try {
            refreshing_token = refreshing_token ? refreshing_token : authService.refreshToken();
            let res = await refreshing_token;
            refreshing_token = null;
            if (res.data) {
                storage.setToken(res.data.Authorization);
                storage.setRefreshToken(res.data.refreshToken);
            }
            return api(config);
        } catch (err) {
            throw transformError(error);
        }
    }
    throw transformError(error);
});

function transformResponse(axiosResponse) {
    return axiosResponse.data.result
}

function transformError(axiosError) {
    throw {
        status: axiosError.response.status,
        message: axiosError.response.data.error.message
    }
}

const result = {

}

export default api;
