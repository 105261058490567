import React from 'react';

const LoadingIcon = (props) => {
    return (
        <svg {...props} width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M80.1317 41C81.1635 41 82.0044 40.163 81.9574 39.1322C81.6176 31.6783 79.2488 24.4453 75.0903 18.2216C70.5851 11.4792 64.1818 6.22413 56.69 3.12094C49.1982 0.0177462 40.9545 -0.794191 33.0013 0.787804C25.0481 2.3698 17.7426 6.27467 12.0086 12.0086C6.27467 17.7426 2.3698 25.0481 0.787803 33.0013C-0.794191 40.9545 0.0177463 49.1982 3.12094 56.69C6.22413 64.1818 11.4792 70.5851 18.2216 75.0903C24.4453 79.2488 31.6783 81.6176 39.1322 81.9574C40.163 82.0044 41 81.1635 41 80.1317C41 79.0998 40.1629 78.2683 39.1324 78.2166C32.4179 77.8797 25.9067 75.7313 20.2976 71.9834C14.1697 67.8888 9.39351 62.0691 6.57313 55.2601C3.75276 48.4511 3.01482 40.9587 4.45263 33.7303C5.89045 26.5019 9.43944 19.8622 14.6508 14.6508C19.8622 9.43944 26.5019 5.89045 33.7303 4.45263C40.9587 3.01482 48.4511 3.75276 55.2601 6.57313C62.0691 9.39351 67.8888 14.1697 71.9834 20.2976C75.7313 25.9067 77.8797 32.4179 78.2166 39.1324C78.2683 40.1629 79.0998 41 80.1317 41Z" fill="currentColor"/>
        </svg>
    );
};

export default LoadingIcon;