import React from 'react';

const SubscriptionIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5466 4.79311V2.37931C12.5466 1.61754 11.9004 1 11.1032 1H2.44332C1.6462 1 1 1.61754 1 2.37931V12.0345C1 12.7963 1.6462 13.4138 2.44332 13.4138H4.6083" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.52588 5.62109H7.49501" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.52588 3.25586H10.0208" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.52588 7.98438H5.33003" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.6703 15.0005C13.0617 15.0005 15.0002 13.1479 15.0002 10.8625C15.0002 8.57723 13.0617 6.72461 10.6703 6.72461C8.27892 6.72461 6.34033 8.57723 6.34033 10.8625C6.34033 13.1479 8.27892 15.0005 10.6703 15.0005Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.94873 8.79297V11.5516H12.8354" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
};

export default SubscriptionIcon;