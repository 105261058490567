import React from 'react';

const CloseIcon = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.5 7L7 16.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M7 7L16.5 16.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
        </svg>

    );
};

export default CloseIcon;