import * as Styled from './styles';

function Card({children, header,className="",headerClassName="",bodyClassName=""}) {
    return (
        <Styled.Card className={className}>
            {header && <Styled.Header className={headerClassName}>
                {header}
            </Styled.Header>}
            {header && <hr/>}
            <Styled.Body className={bodyClassName}>
                {children}
            </Styled.Body>
        </Styled.Card>
    )
}

export default Card;