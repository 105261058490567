import styled from "styled-components";


export const Card = styled.div`
  background-color : ${props => props.theme.palette.foreground};
  border: 0.5px solid ${props => props.theme.palette.divider};
  border-radius : 15px;
  width: 100%;
  height: auto;
  
  hr {
    height: 0;
    border: none;
    border-top: solid 1px ${props => props.theme.palette.divider};
    width: 95%;
    margin: auto;
  }
`;
export const Header = styled.div`
  padding: 1rem 1.5rem;
`;

export const Body = styled.div`
  padding: 1rem 1.5rem;
`;
