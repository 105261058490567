import moment from "moment-jalaali";

export const objectToDate = ({day, month, year}) => {
    return moment(`${year}/${month}/${day}`, 'jYYYY/jMM/jDD').toDate();
}

export const dateToObject = value => {
    if(!value)
        return null
    const date = new Date(value)
    return {
        day: Number(date.toLocaleDateString("fa-IR-u-nu-latn", {
            day: 'numeric'
        })),
        month: Number(date.toLocaleDateString("fa-IR-u-nu-latn", {
            month: 'numeric'
        })),
        year: Number(date.toLocaleDateString("fa-IR-u-nu-latn", {
            year: 'numeric'
        }))
    }
}

export const getMaximumBirthDateObject = () => {
    const m = moment(new Date());
    m.subtract(17,'jYear')
    m.jMonth(11);
    m.jDate(m.endOf('jMonth'));
    return m.toDate();
}

export const getDayDiff = (date1,date2) => {
    if(!date2 || !date1)
        return null;
    return Math.round(Math.abs(new Date(date2).getTime() - new Date(date1).getTime()) / (1000 * 60 * 60 * 24));
}
