import React from 'react';

const BackIcon = (props) => {
    return (
        <svg {...props} width="20px" height="20px" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 1L8.5 8L1.5 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
};

export default BackIcon;