import styled from "styled-components";
import CloseIcon from "../../icons/CloseIcon";

export const Label = styled.label`
  position: absolute;
  transform-origin: right bottom;
  transform: translate(-0.5rem,-1.8rem);
  background-color: inherit;
  padding: 0 0.5rem;
  color: ${props => props.theme.palette.input.label};
  ${props => props.theme.typography.bodySmall};
`;

export const Input = styled.div`
  position: relative;
  display: flex;
  border: solid 1px ${props => props.theme.palette.divider};
  border-radius: ${props => props.theme.borderRadius};
  align-items: center;
  padding: 0 ${props => props.theme.spacing[2]};
  justify-content: space-between;
  cursor: text;
  background-color: ${props => props.theme.palette.foreground};
  &.error{
    border-color: ${props => props.theme.palette.input.error.border};
    ${Label}{
      color: ${props => props.theme.palette.input.error.label};
    }
  }
  &:focus-within{
    border-color: ${props => props.theme.palette.input.focused.border};
    caret-color: ${props => props.theme.palette.input.focused.border};
    ${Label}{
      color: ${props => props.theme.palette.input.focused.border};
    }
  }
  & .baseInput{
    width: 100%;
    padding-left: 2rem;
  }
  &  input{
    border: none;
    height: 56px;
    color:${props => props.theme.palette.input.color};
    background-color: transparent;
    font-size: 16px;
    &::placeholder {
      color: ${props => props.theme.palette.input.placeholder};
      font-size: 16px;
    }
  }
`;

export const ClearIcon = styled(CloseIcon)`
  cursor: pointer;
  position: absolute;
  left: 10px;
  width: 20px;
  height: 20px;
  color: ${props => props.theme.palette.input.placeholder};
`;
export const ErrorMessage = styled.div`
  color: ${props => props.theme.palette.input.error.color};
  height:  30px;
  padding-right: ${props => props.theme.spacing[2]};
  ${props => props.theme.typography.subtitle};
`;
export const Suffix = styled.span`
  color: ${props => props.theme.palette.input.placeholder};
  margin-left: 1rem;
  ${props => props.theme.typography.bodySmall};
`;