import * as Styled from './styles';
import useModal from "../../../hooks/useModal";
import FeatureModal from "./featureModal/FeatureModal";
import UnlockIcon from "../../../components/icons/UnlockIcon";

const Features = ({ selectedType }) => {
    const profeatures = [
        {
            title: 'دریافت برنامه غذایی',
            description: 'دریافت برنامه غذایی',
            image: 'feature.png'
        },
        {
            title: 'کالری شماری و نمایش پروتئین غذا',
            description: 'کالری شماری و نمایش پروتئین غذا',
            image: 'feature.png'
        },
        {
            title: 'تعیین هدف، کاهش یا افزایش وزن',
            description: 'تعیین هدف، کاهش یا افزایش وزن',
            image: 'feature.png'
        },
        {
            title: 'ماکروشماری، محاسبه مقدار مواد مغذی',
            description: 'ماکروشماری، محاسبه مقدار مواد مغذی',
            image: 'feature.png'
        }
        ,
        {
            title: 'ویدئوهای ورزشی،از مبتدی تا پیشرفته',
            description: 'ویدئوهای ورزشی،از مبتدی تا پیشرفته',
            image: 'feature.png'
        }
        ,
        {
            title: 'دستور پخت غذاهای سالم و خوشمزه',
            description: 'دستور پخت غذاهای سالم و خوشمزه',
            image: 'feature.png'
        }
        ,
        {
            title: 'محاسبه کالری دوران شیردهی',
            description: 'محاسبه کالری دوران شیردهی',
            image: 'feature.png'
        }
    ]

    const regularfeatures = [
        {
            title: 'کالری شماری و نمایش پروتئین غذا',
            description: 'کالری شماری و نمایش پروتئین غذا',
            image: 'feature.png'
        },
        {
            title: 'تعیین هدف، کاهش یا افزایش وزن',
            description: 'تعیین هدف، کاهش یا افزایش وزن',
            image: 'feature.png'
        },
        {
            title: 'ماکروشماری، محاسبه مقدار مواد مغذی',
            description: 'ماکروشماری، محاسبه مقدار مواد مغذی',
            image: 'feature.png'
        }
        ,
        {
            title: 'دستور پخت غذاهای سالم و خوشمزه',
            description: 'دستور پخت غذاهای سالم و خوشمزه',
            image: 'feature.png'
        }
        ,
        {
            title: 'محاسبه کالری دوران شیردهی',
            description: 'محاسبه کالری دوران شیردهی',
            image: 'feature.png'
        }
    ]

    const featureModalProps = useModal();
    if (selectedType === 'pro') {
        return (
            <Styled.Wrapper>
                <FeatureModal {...featureModalProps}/>
                <div className="shop-table-header">
                    <UnlockIcon/>
                    ویژگی های خرید اشتراک پیشرفته
                </div>
                <div className="shop-table">
                    {profeatures.map(feature => (
                        <div key={feature.title} className="shop-table-row">
                            <div className="shop-table-content">{feature.title}</div>
                            {/* <div
                                className="shop-table-more-button"
                                onClick={() => featureModalProps.onOpen(feature)}
                            >
                                بیشتر
                            </div> */}
                        </div>
                    ))}
                </div>
            </Styled.Wrapper>
        )
    } else {
        return (
            <Styled.Wrapper>
                <FeatureModal {...featureModalProps}/>
                <div className="shop-table-header">
                    <UnlockIcon/>
                    ویژگی های خرید اشتراک پایه
                </div>
                <div className="shop-table">
                    {regularfeatures.map(feature => (
                        <div key={feature.title} className="shop-table-row">
                            <div className="shop-table-content">{feature.title}</div>
                            {/* <div
                                className="shop-table-more-button"
                                onClick={() => featureModalProps.onOpen(feature)}
                            >
                                بیشتر
                            </div> */}
                        </div>
                    ))}
                </div>
            </Styled.Wrapper>
        )
    }

}

export default Features;