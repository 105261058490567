import React from 'react';

const useLockScroll = (ref) => {

    const onTouchMove = (e) => {
        e.preventDefault();
    }

    const onLock = () => {
        ref.current.addEventListener('touchmove',onTouchMove)
        // document.body.style.overflowY = "hidden";
    }
    const onUnlock = () => {
        ref.current.removeEventListener('touchmove',onTouchMove)
        // document.body.style.overflowY = "unset";
    }
    return {onLock,onUnlock}
};

export default useLockScroll;