import React from 'react';
import * as Styled from './styles';
import Typography from "../typography/Typography";
import Container from "../container/Container";

const PageHeader = ({title,addon}) => {
    return (
        <Styled.Wrapper>
            <Container>
                <Typography variant={"heading4"}>{title}</Typography>
                {addon}
            </Container>
        </Styled.Wrapper>
    );
};

export default PageHeader;