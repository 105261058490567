import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: ${props => props.theme.spacing[2]};
  text-align: center;
  ${props => props.theme.typography.subtitle};
`;

export const ResendButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: ${props => props.theme.palette.text.highlight};
  text-decoration: underline;
  text-decoration-color: ${props => props.theme.palette.text.highlight};
  margin: 0;
`;
