import React from 'react';
import * as Styled from "./styles";

const mapping = {
    heading1:'h1',
    heading2:'h2',
    heading3:'h3',
    bodyLarge:'p',
    bodyMedium:'p',
    bodySmall:'p',
}

const Typography = ({variant,as,children,className}) => {
    return (
        <Styled.Wrapper variant={variant} as={as || mapping[variant]} className={className}>
            {children}
        </Styled.Wrapper>
    );
};

export default Typography;