import React from 'react';
import * as Styled from './styles';
import Typography from "../typography/Typography";
import Container from "../container/Container";

const SecondHeader = ({title, addon}) => {
    return (
        <Styled.Wrapper2>
            <Container>
                <Typography variant={"heading4"}>{title}</Typography>
                {addon}
            </Container>
        </Styled.Wrapper2>
    );
};

export default SecondHeader;