import React from 'react';
import * as Styled from './styles';
const Container = ({children,className,as}) => {
    return (
        <Styled.Wrapper className={className} as={as}>
            {children}
        </Styled.Wrapper>
    );
};

export default Container;