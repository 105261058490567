import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import authService from "../services/authService";
import storage from "../config/storage";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import { Buffer } from "buffer";
import moment from "moment-jalaali";

export const AuthContext = React.createContext();
const AuthProvider = ({ children }) => {
  const hasToken = storage.getToken();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(hasToken);
  const [loginLoading, setLoginLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [hasActiveAutoRenewalSubscription, setActiveAutoRenewalSubscription] = useState(false);
  const [email, setEmail] = useState(null);
  const [searchParams] = useSearchParams();

  // if (hasToken) {
  //   const token = storage.getToken();
  //   const decoded = jwt_decode(token);

  //   const decodedPhoneNumber = decoded.user.phoneNumber;
  //   const decodedEmail = decoded.user.email;

  //   if (!phoneNumber) {
  //     setPhoneNumber(decodedPhoneNumber);
  //   }

  //   if (!email) {
  //     setEmail(decodedEmail);
  //   }
  // }

  const getUser = () => {
    return authService.getCurrentUser().then((res) => {
      const user = res.user;
      return {
        ...user,
        subscription:
          new Date(user.subscription?.expirationTime).getTime() > Date.now()
            ? user.subscription?.expirationTime
            : null,
        subscriptionType: user.subscription?.subscriptionType,
        paymentType: user.subscription?.paymentType,
        hasActiveAutoRenewalSubscription: (user.subscription?.paymentType == "bazaarDirectPay" || user.subscription?.paymentType == "directDebit") && (user.subscription?.expirationTime >= moment().toISOString())
      };
    });

  };

  const onLogout = () => {
    storage.clear();
    setUser(null);
  };

  const onLogin = (credentials) => {
    setLoginLoading(true);
    return authService
      .login(credentials)
      .then((res) => {
        if (res.userType === "COMPLETE_USER") {
          storage.setToken(res.Authorization);
          return getUser();
        } else {
          toast.error("لطفا در اپلیکیشن اطلاعات خود را تکمیل کنید.");
        }
      })
      .then((user) => {
        setUser(user);
        setLoginLoading(false);
      })
      .catch((e) => {
        setLoginLoading(false);
        throw e;
      });
  };

  const onLoginGoogle =(credentials)=>{
    setLoginLoading(true);
    return authService
      .loginGOOGLE(credentials)
      .then((res) => {
        if (res.userType === "COMPLETE_USER") {
          storage.setToken(res.Authorization);
          return getUser();
        } else {
          toast.error("لطفا در اپلیکیشن اطلاعات خود را تکمیل کنید.");
        }
      })
      .then((user) => {
        setUser(user);
        setLoginLoading(false);
      })
      .catch((e) => {
        setLoginLoading(false);
        throw e;
      });
  }

  useEffect(() => {
    if (hasToken && !user) {
      setLoading(true);
      getUser()
        .then((user) => {
          if (user.karafsSignupCompleted) {
            setUser(user);
          } else {
            toast.error("لطفا در اپلیکیشن اطلاعات خود را تکمیل کنید.");
            onLogout();
          }
        })
        .then(() => setLoading(false))
        .catch(() => {
          setLoading(false);
          onLogout();
        });
    }

    let ph = searchParams.get("ph");

    if (ph) {
      if (ph !== phoneNumber) {
        onLogout();
      }
    }
  }, [hasToken, phoneNumber, searchParams, user]);

  return (
    <AuthContext.Provider
      value={{
        login: onLogin,
        loginGoogle: onLoginGoogle,
        loginLoading,
        logout: onLogout,
        isLoading: loading,
        user,
        phoneNumber,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
