import React from 'react';
import * as Styled from './styles';
import BaseInput from "../baseInput/BaseInput";
import {useField} from "formik";
import {toEnglishNumber} from "../../../utils/number";

const Input = React.forwardRef(({className,...props},ref) => {

    const [field, meta, helpers] = useField(props.name);


    const onChange = (e) => {
        switch (props.type){
            case 'tel':{
                let value = toEnglishNumber(e.target.value);
                if((Number(value)===0 || Number(value)) && value.length<12){
                    helpers.setValue(value);
                }
                return;
            }
            case 'number':{
                let value = toEnglishNumber(e.target.value);
                if(Number(value) || !value){
                    helpers.setValue(value);
                }
                return;
            }
            default:{
                field.onChange(e)
            }
        }
    }

    const onBlur = e => {
        if(props.type==='number'){
            helpers.setValue(Number(field.value) || "");
        }
        field.onBlur(e);
    }

    const type = props.type === 'number' ? 'tel' : props.type;
    const inputMode = props.type === 'tel' ? 'tel' : props.type==='number' ? 'decimal' : 'text';
    return (
        <>
            <BaseInput className={className} {...props} {...meta} {...helpers} {...field}>
                <Styled.Input ref={ref} {...field} placeholder={props.placeholder} {...props} onBlur={onBlur} onChange={onChange} type={type} inputMode={inputMode}/>
            </BaseInput>
        </>

    );
});

export default Input;

