import React, { useContext, useEffect, useState } from 'react';
import * as Styled from './styles';

import Features from "./features/Features";
import Container from "../../components/container/Container";
import ShopPackages from "./ShopPackages/ShopPackages";
import useModal from "../../hooks/useModal";
import DiscountModal from "./DiscountModal/DiscountModal";
import SubscriptionStatus from "../../components/subscriptionStatus/SubscriptionStatus";
import Loading from "../../components/loading/Loading";
import PageHeader from "../../components/pageHeader/PageHeader";
import DiscountIcon from "../../components/icons/DiscountIcon";
import Button from "../../components/button/Button";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import subscriptionService from "../../services/subscriptionService";
import classNames from "classnames";
import { AuthContext } from "../../providers/AuthProvider";
import storage from '../../config/storage';
import UserProfileStatus from '../../components/subscriptionStatus/UserProfileStatus';
import SecondHeader from '../../components/pageHeader/SecondHeader';
import ShopCard from './ShopPackages/shopCard/ShopCard';
import AutoRenewCard from './ShopPackages/shopCard/AutoRenewCard';
import moment from 'moment-jalaali';
import { BrowserRouter, Route, Routes, useSearchParams } from "react-router-dom";
import Bank from './Bank';

const Shop = () => {

    const navigate = useNavigate();
    const { user, phoneNumber, logout } = useContext(AuthContext);
    const [selectedIndex, setSelectedIndex] = useState(1);
    const [loading, setLoading] = useState(true);
        const [packages, setPackages] = useState([])
        const [selectedSwitchType, setSelectedSwitchType] = useState("pro");
        const [selectedType, setSelectedType] = useState(storage.getVersion() >= 2 ? "pro" : "regular");
        const discountModalProps = useModal();
        const bankSelectionModalProps = useModal();

        const getSelectedTypePackages = (type) => {
            return packages.filter(shopPackage => shopPackage.priceInfo.planSubscriptionType === type);
        }

        const packageTypeHasDiscount = (type) => {
            return packages.filter(shopPackage => shopPackage.priceInfo.planSubscriptionType === type && shopPackage.priceInfo.totalPercentageDiscount).length > 0;
        }

        useEffect(() => {
            subscriptionService.getPackages().then(({ packages }) => {
                setPackages(packages.sort((a, b) => b.order - a.order));
                setLoading(false)
            })
        }, []);


        const onChangeDiscount = ({ discountCode }) => {
            subscriptionService.getDiscountPackages({ discountCode }).then(({ packages }) => {
                if (!packages)
                    throw new Error();
                toast.success("کد تخفیف اعمال شد.")
                setPackages(packages.sort((a, b) => b.order - a.order));
                setLoading(false);
            }).catch(() => {
                setLoading(false)
                toast.error("کد تخفیف صحیح نمیباشد.")
            })

        }

        const onSelectBank = (bank) => {
            // if (setSelectedSwitchType !== type)
            //     setSelectedType(type);
            // setSelectedSwitchType(type);
        }

        const onSelectType = (type) => {
            if (setSelectedSwitchType !== type)
                setSelectedType(type);
            setSelectedSwitchType(type);
        }

        const onSelectPackage = (index) => {
            setSelectedIndex(index);
        }

        const onBuy = () => {
            let type = getSelectedTypePackages(selectedType)[selectedIndex].priceInfo.paymentType;
            if (type == "directDebit") {
                let id = getSelectedTypePackages(selectedType)[selectedIndex].id;
                let token = getSelectedTypePackages(selectedType)[selectedIndex].transactionToken;
                navigate("/banks/" + id + "?transactionToken=" + token);
            } else {
                subscriptionService
                    .getTransaction(getSelectedTypePackages(selectedType)[selectedIndex].transactionToken)
                    .then((res) => {
                        if (res.isGift) {
                            user.subscription = res.expirationTime;
                            window.location.href = 'karafsapp://karafs/reload';
                        } else {
                            window.location.href = res.url;
                        }
                    });
            }
        }

        return (
            <Styled.Wrapper>
                <PageHeader title={"خرید اشتراک"}
                    addon={<SubscriptionStatus />}
                />
                <SecondHeader addon={<UserProfileStatus />}
                />

                <DiscountModal {...discountModalProps} onOk={onChangeDiscount} />

                <Container>

                {loading ?
                    <Loading /> :
                    <>
                        {user.hasActiveAutoRenewalSubscription ?
                            <AutoRenewCard
                                expirationTime={user.subscription}
                            />
                            :
                            <>
                                <ShopPackages
                                    selectedIndex={selectedIndex}
                                    onSelectPackage={onSelectPackage}
                                    packages={getSelectedTypePackages(selectedType)}
                                />

                                <Styled.DiscountCode onClick={discountModalProps.onOpen}>
                                    <DiscountIcon />
                                    &nbsp;
                                    کد تخفیف
                                </Styled.DiscountCode>
                                <Features selectedType={selectedSwitchType} />
                                <Styled.SubmitButton>
                                    <Button onClick={onBuy} disabled={!packages}>
                                        خرید اشتراک {getSelectedTypePackages(selectedType)[selectedIndex]?.theme?.planName}
                                    </Button>
                                </Styled.SubmitButton>
                            </>
                        }
                    </>
                }

                {/* <Styled.LogoutButton >
                <a onClick={() => logout()}>
                    خروج از حساب کاربری
                </a>
            </Styled.LogoutButton> */}
            </Container>
        </Styled.Wrapper>
    );
};

export default Shop;
