
export const device = {
    mobileS: `min-width: 320px`,
    // mobileM: `min-width: 375px`,
    // mobileL: `min-width: 425px`,
    // tablet: `min-width: 768px`,
    mobile: `max-width: 1279px`,
    laptop: `min-width: 1280px`,
    // laptopL: `min-width: 1440px`,
    // desktop: `min-width: 2560px`,
}

const palette = {
    system: {
        primary: {
            dark: '#2E996C',
            medium: '#29CC88',
            light: '#73E6B6',
        },
        secondary: {
            medium: '#FA8B3E',
            light: '#FFA466',
        },
        tertiary: {
            light: '#FFA9A6',
            medium: '#FF584D'
        }
    },
    label: {
        primary: '#131314',
        secondary: '#676773',
        tertiary: '#A6A6B3',
    },
    semantic: {
        calories: '#FA8B3E',
        exercise: '#B273FB',
        food: '#FCCA32',
        protein: '#FF6C84',
        steps: '#69D4C8',
        water: '#3DC2FF',
        weight: '#7B91FD',
    },
    white: '#FFFFFF',
    background: '#F9FBFC',
    divider: '#DFE3EB',
    disable: '#DCDDE0',
}
const theme = {
    palette: {
        text: {
            highlight: palette.system.primary.medium,
            primary: palette.label.primary,
            secondary: palette.label.secondary,
            tertiary: palette.label.tertiary,
            white: palette.white
        },
        button: {
            primary: {
                background: palette.system.primary.medium,
                color: palette.white,
                shadow: '#23AA71',
            },
            disabled: {
                background: palette.disable,
                color: palette.white,
                shadow: '#C6C7CA'
            },
            error: {
                background: palette.system.tertiary.medium,
                color: palette.white,
                shadow: ''
            }
        },
        input: {
            color: palette.label.primary,
            background: palette.white,
            placeholder: palette.label.tertiary,
            label: palette.label.tertiary,
            focused: {
                border: palette.system.primary.medium,
                label: palette.system.primary,
            },
            error: {
                border: palette.system.tertiary.medium,
                label: palette.system.tertiary.medium,
                color: palette.system.tertiary.medium
            },
            otp: {
                background: '#EEF8F3',
                color: palette.system.primary.medium,
                error: {
                    color: palette.system.tertiary.medium,
                    background: palette.system.tertiary.light,
                    border: palette.system.tertiary.medium
                }
            },
            gender: {
                color: palette.label.tertiary,
                selected: {
                    color: palette.label.primary,
                    background: '#F4FEFA',
                }
            }
        },
        toast: {
            success: {
                background: palette.system.primary.light,
                color: palette.white
            },
            error: {
                background: palette.system.tertiary.medium,
                color: palette.white
            }
        },
        background: palette.background,
        foreground: palette.white,
        publicBackground: palette.white,
        divider: palette.divider,
        shadow: '#eee',
    },
    typography: {
        fontFamily: 'Vazir',
        heading1: {
            fontSize: '26px',
            fontWeight: 700,
            lineHeight: '44px',
            [`@media (${device.laptop})`]: {
                fontSize: '40px',
                lineHeight: '62.5px',
                fontWeight: 900,
            }
        },
        heading2: {
            fontSize: '22px',
            lineHeight: '30px',
            fontWeight: 700,
            [`@media (${device.laptop})`]: {
                fontSize: '32px',
                fontWeight: 700,
                lineHeight: '43px',
            }
        },
        heading3: {
            fontSize: '20px',
            lineHeight: '26px',
            fontWeight: 700,
            [`@media (${device.laptop})`]: {
                fontSize: '22px',
                fontWeight: 700,
                lineHeight: '30px',
            }
        },
        heading4: {
            fontSize: '18px',
            lineHeight: '24px',
            fontWeight: 700,
            [`@media (${device.laptop})`]: {
                fontSize: '18px',
                fontWeight: 700,
                lineHeight: '24px',
            }
        },
        bodyLarge: {
            fontSize: '18px',
            lineHeight: '24px',
            fontWeight: 400,
            [`@media (${device.laptop})`]: {
                fontSize: '22px',
                fontWeight: 400,
                lineHeight: '30px',
            }
        },
        bodyMedium: {
            fontSize: '16px',
            lineHeight: '30px',
            fontWeight: 400,
            [`@media (${device.laptop})`]: {
                fontSize: '18px',
                fontWeight: 400,
                lineHeight: '24px',
            }
        },
        bodySmall: {
            fontSize: '12px',
            lineHeight: '18px',
            fontWeight: 400,
            [`@media (${device.laptop})`]: {
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '24px',
            }
        },
        subtitle: {
            fontSize: '10px',
            lineHeight: '18px',
            fontWeight: 400,
            [`@media (${device.laptop})`]: {
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '24px',
            }
        },
        caption: {},
        button: {},
    },
    spacing: [
        '4px', '8px', '16px', '32px', '48px', '72px', '96px'
    ],
    borderRadius: '24px'
}
export default theme;
