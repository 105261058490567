import styled from "styled-components";

export const Wrapper = styled.div`

  color: ${props => props.theme.palette.text.secondary};
  .discountInput {
    margin-top: 2rem;
  }
`;
export const Title = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-left: 0.5rem;
  }
`;


export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  .submit-button{
    flex-basis: 50%;
  }
  .cancel-button{
    flex-basis: 40%;
  }
`;