import React from 'react';
import * as Styled from './styles';
import LoadingIcon from "../icons/LoadingIcon";

const Loading = ({className}) => {
    return (
        <Styled.Wrapper>
            <Styled.LoadingIcon as={LoadingIcon} className={className}/>
        </Styled.Wrapper>
    );
};

export default Loading;