import React from 'react';
import * as Styled from './styles';

const BaseInput = ({name,value,onClear=true,label,suffix,error,touched,children,setValue,className})     => {
    return (
        <div className={className}>
            <Styled.Input className={(touched && error ? "error" : "")}>
                <Styled.Label htmlFor={name}>{label}</Styled.Label>
                {children}
                <Styled.Suffix>
                    {suffix}
                </Styled.Suffix>
                {onClear && !!value &&  <Styled.ClearIcon onClick={() => {setValue("")}}/>}
            </Styled.Input>
            <Styled.ErrorMessage>
                {touched && error}
            </Styled.ErrorMessage>
        </div>
    );
};

export default BaseInput;