import {createGlobalStyle} from 'styled-components';

const GlobalStyle = createGlobalStyle`


  #root {
    height: 100%;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: vazir, sans-serif;
  }

  html {
    font-size: 16px;
    direction: rtl;
   
    height: 100%;
  }

  body {
    height: 100%;
    background-color: ${props => props.theme.palette.background};
    color: ${props => props.theme.palette.text.primary};
    //margin-left: calc(100vw - 100%);
  }
  
  
  ul{
    list-style: none;
  }
  
  a{
    text-decoration: none;
    color: inherit;
  }
  button{
    font-size: 16px;
  }
  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  //body::-webkit-scrollbar {
  //  width:5px;               /* width of the entire scrollbar */
  //}
  //
  //body::-webkit-scrollbar-track {
  //  background: #ccc;        /* color of the tracking area */
  //}
  //
  //body::-webkit-scrollbar-thumb {
  //  background-color: #19A672;    /* color of the scroll thumb */
  //  border-radius: 20px;       /* roundness of the scroll thumb */
  //  //border: 3px solid orange;  /* creates padding around scroll thumb */
  //}
`;

export default GlobalStyle;