import React, {useState} from 'react';

const useModal = () => {

    const [state,setState] = useState({
        isOpen:false,
        data:null
    });

    const onOpen = (data) => {
        setState({
            data,
            isOpen: true
        })
    }

    const onClose = () => {
        setState(state => ({
            data:state.data,
            isOpen: false,
        }))
    }

    return {onOpen,onClose,...state};
};

export default useModal;