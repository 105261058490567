import React, {useEffect} from 'react';
import AuthProvider from "./AuthProvider";
import theme from "../config/theme";
import {ThemeProvider} from "styled-components";
import setup from "../config/setup";

const AppProvider = ({children}) => {

    setup();

    return (
        <AuthProvider>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </AuthProvider>
    );
};

export default AppProvider;