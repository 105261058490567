import React from 'react';
import * as Styled from './styles';
import classNames from "classnames";

const Backdrop = ({isOpen,onClick,className}) => {
    return (
        <Styled.Wrapper onClick={onClick} className={classNames(className,{'backdrop-open':isOpen,'backdrop-close':!isOpen})}/>
    );
};

export default Backdrop;