import {getUrlParam} from "../utils/url";
import storage from "./storage";

function setup(){
    initStorage();
}

function initStorage(){
    const tokenParam = getUrlParam("token");
    const market = getUrlParam("market");
    tokenParam && storage.setToken(tokenParam);
    market && storage.setMarket(market);
}
export default setup;