import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/pagination";
import * as Styled from './styles';
import useMediaQuery from "../../../hooks/useMediaQuery";
import { device } from "../../../config/theme";
import { Pagination } from "swiper";

const BankCards = ({ banks, onSelectBank }) => {
    const isDesktop = useMediaQuery(device.laptop);
    return (
        <Styled.ChildWrapper>
                <ul className="container-cards inline-b" id="banks-listS">
                    {banks.map((bank, index) => (
                        <li key={bank.code} className="item inline-b-item" id={bank.code}>
                            <div className="flex-column card" onClick={() => onSelectBank(bank)}>
                                <img src={bank.logo} height={60}/>
                                <span className={"pro-label"}>{bank.name}</span>
                            </div>
                        </li>
                    )
                    )}
                </ul>
        </Styled.ChildWrapper>
    )
}

export default BankCards;