import React from 'react';
import * as Styled from './styles';
import {ToastContainer} from "react-toastify";
import CloseIcon from "../icons/CloseIcon";

const Toast = () => {
    return (
        <Styled.Wrapper>
            <ToastContainer icon={false} closeButton={CloseIcon} className={"toast-container"} toastClassName={"toast"} rtl={true} position={"bottom-center"}
                            autoClose={2000}
                            hideProgressBar={true}
                            closeOnClick={true}
                            limit={1}
                            pauseOnHover={true}/>
        </Styled.Wrapper>
    );
};

export default Toast;