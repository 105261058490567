import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.2);
  transition: opacity 200ms ease-in;
  z-index: 100;
  &.nav-backdrop.backdrop-open{
    z-index: 50;
  }
  
  &.backdrop-open{
    opacity: 1;
    display: unset;
  }
  &.backdrop-close{
    display: none;
    opacity: 0;
  }
`;