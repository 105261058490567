import styled from "styled-components";
import {Form as FormikForm} from 'formik';

export const Form = styled(FormikForm)`
  margin-bottom: ${props => props.theme.spacing[2]};
  .submitButton{
    margin-top: ${props => props.theme.spacing[3]};
  }

  .google{
    margin:1rem 0 0 1rem;
    width:100%;
    height: 100%;
    display:flex;
    aling-items:center;
    justify-content:center;
  }
`;