import React, {useEffect, useState} from 'react';
import Modal from "../../../../components/modal/Modal";
import * as Styled from './styles';

const FeatureModal = ({data:feature,...props}) => {
    const [Image, setImage] = useState("");

    useEffect(() => {
        if(feature){
            import(`../../../../assets/images/features/${feature.image}`).then(image => {
                setImage(image.default);
            });
        }
    }, [feature]);
    return (
        <Modal title={feature?.title} {...props}>
            <Styled.Image src={Image}/>
            <div>{feature?.description}</div>
        </Modal>
    );
};

export default FeatureModal;