import React, {useEffect, useRef} from 'react';
import Modal from "../../../components/modal/Modal";
import * as Styled from './styles';
import {Form, Formik} from "formik";
import Input from "../../../components/inputs/Input/Input";
import Button from "../../../components/button/Button";
import DiscountIcon from "../../../components/icons/DiscountIcon";

const DiscountModal = ({onClose,isOpen,onOk}) => {
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current && isOpen) {
            inputRef.current.focus();
        }
    }, [isOpen]);
    return (
        <Modal title={<Styled.Title><DiscountIcon/> کد تخفیف</Styled.Title>} isOpen={isOpen} onClose={onClose}>
            <Styled.Wrapper>
                <div>
                    کد تخفیف خود را وارد کنید.
                </div>
                <Formik
                    initialValues={{
                        discountCode:'',
                    }}
                    onSubmit={values => {
                        onOk(values);
                        onClose();
                    }}
                >
                    <Form>
                        <Input ref={inputRef} className={"discountInput"} name={"discountCode"} label={"کد تخفیف"}/>
                        <Styled.Actions>
                            <Button className={"submit-button"} type={"submit"} fullWidth>فعالسازی</Button>
                            <Button className={"cancel-button"} variant={"error"} outlined fullWidth onClick={onClose}>انصراف</Button>
                        </Styled.Actions>

                    </Form>

                </Formik>
            </Styled.Wrapper>

        </Modal>
    );
};

export default DiscountModal;