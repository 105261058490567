import React, { useContext, useEffect, useState } from 'react';
import * as Styled from './styles';

import Features from "./features/Features";
import Container from "../../components/container/Container";
import ShopPackages from "./ShopPackages/ShopPackages";
import useModal from "../../hooks/useModal";
import DiscountModal from "./DiscountModal/DiscountModal";
import SubscriptionStatus from "../../components/subscriptionStatus/SubscriptionStatus";
import Loading from "../../components/loading/Loading";
import PageHeader from "../../components/pageHeader/PageHeader";
import DiscountIcon from "../../components/icons/DiscountIcon";
import Button from "../../components/button/Button";
import { toast } from "react-toastify";
import { redirect, useNavigate } from "react-router-dom";
import subscriptionService from "../../services/subscriptionService";
import classNames from "classnames";
import { AuthContext } from "../../providers/AuthProvider";
import storage from '../../config/storage';
import UserProfileStatus from '../../components/subscriptionStatus/UserProfileStatus';
import SecondHeader from '../../components/pageHeader/SecondHeader';
import ShopCard from './ShopPackages/shopCard/ShopCard';
import AutoRenewCard from './ShopPackages/shopCard/AutoRenewCard';
import moment from 'moment-jalaali';
import BankCards from './ShopPackages/BankCards';
import { useParams, useLocation } from "react-router-dom";

const Bank = () => {
    const navigate = useNavigate();
    let { id } = useParams();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const transactionToken = queryParams.get('transactionToken');

    const { user, phoneNumber, logout } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [banks, setBanks] = useState([])
    const [bank, selectedBank] = useState([])

    useEffect(() => {
        subscriptionService.getVandarBanks()
            .then((res) => {
                setBanks(res);
                setLoading(false)
            });
    }, []);

    const onSelectBank = (bank) => {
        selectedBank(bank);
    }

    const goToGateway = () => {
        subscriptionService.getVandarGatewayURL(bank.code, id, transactionToken)
            .then((res) => {
                console.log(res);
                if (res.url) {
                    window.location.href = res.url;
                }
            });
    }

    return (
        <Styled.Wrapper>
            <PageHeader title={"انتخاب بانک"}
                addon={<SubscriptionStatus />}
            />

            <Container>
                {loading ?
                    <Loading /> :
                    <>
                        <Styled.ChildWrapper>
                            <BankCards
                                onSelectBank={onSelectBank}
                                banks={banks}
                            />
                        </Styled.ChildWrapper>
                        <Styled.SubmitButton>
                            <Button onClick={goToGateway} disabled={bank.code == undefined}>
                                انتخاب {bank.name}
                            </Button>
                        </Styled.SubmitButton>
                    </>
                }
            </Container>
        </Styled.Wrapper>
    );
};

export default Bank;
