import styled from "styled-components";

export const Wrapper = styled.div`
  padding-bottom: 150px;
`;

export const ChildWrapper = styled.div`
  background: ${props => props.theme.palette.foreground};
  border-radius: ${props => props.theme.borderRadius};
  margin-top: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
`;

export const DiscountCode = styled.div`
  @media (max-width: 768px) {
    width: 90%;
    margin: 40px auto 10px auto;

  }
  display: flex;
  align-items: center;
  width: 460px;
  background: ${props => props.theme.palette.foreground};
  border-radius: ${props => props.theme.borderRadius};
  height: 56px;
  font-weight: bold;
  color: ${props => props.theme.palette.text.highlight};
  font-size: 15px;
  cursor: pointer;
  margin-top: 40px;
  padding-right: 8px;
`;

export const SubmitButton = styled.div`

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${props => props.theme.palette.background};
  border-top-left-radius: ${props => props.theme.borderRadius};
  border-top-right-radius: ${props => props.theme.borderRadius};
  border: solid 1px ${props => props.theme.palette.divider};
  //padding: 2rem 1rem 2rem calc(100vw - 100%);
  padding: 2rem 1rem;
  z-index: 100;
  & > button {
    display: block;
    margin: auto;
    width: 90%;
    max-width: 350px;
  }

`;

export const LogoutButton = styled.div`
    z-index: 100;
    background-color: transparent;
    color: red;
    outline: none;
    display: flex;
    align-items: center;
    text-align: center;
    margin: 10px;
    cursor: pointer; 

   
`;

export const SwitchButton = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.theme.palette.shadow};
  border-radius: ${props => props.theme.borderRadius};
  padding: 3px;
  margin: 3rem 0;
  .switch-button__choice{
    width: 50%;
    padding: 1rem;
    color: ${props => props.theme.palette.text.tertiary};
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
    &.selected{
      color: ${props => props.theme.palette.text.highlight};
      background-color: ${props => props.theme.palette.foreground};
      border-radius: ${props => props.theme.borderRadius};
      cursor: auto;
    }
  }
`;

