import * as Styled from "./styles";
import React from "react";
import Typography from "../../../../components/typography/Typography";
import Card from "../../../../components/card/Card";
import AutoImage from "../../../../assets/images/direct.svg";
function ShopCard({ shopPackage, isSelected, onClick }) {
  const discountAmount = shopPackage.priceInfo.totalPercentageDiscount;
  const promotionName = shopPackage.theme.promotionName;
  const AutomatiRenewal =
    shopPackage.priceInfo.paymentType === "bazaarDirectPay";

  const header = (
    <Styled.Header>
      <div className="main">
        <div className="title_box">
          {AutomatiRenewal && (
            <img src={AutoImage} className={"promotion-image"} />
          )}
          <div>
            {AutomatiRenewal && (
              <p>
                {shopPackage.theme.planName +
                  "-" +
                  shopPackage.priceInfo.price.toLocaleString()}{" "}
                تومان
              </p>
            )}
            {AutomatiRenewal ? (
              <p className={"title"}>تمدید خودکار</p>
            ) : (
              <div className={"title"}>{shopPackage.theme.planName}</div>
            )}
          </div>
        </div>
        {!AutomatiRenewal && (
          <Typography variant={"bodySmall"} className={"profit"}>
            {shopPackage.theme.subTitle || "صفر تومان سود"}
          </Typography>
        )}
      </div>
      {shopPackage.theme.promotionImage && (
        <img
          className={"promotion-image"}
          alt={"promotion-image"}
          src={shopPackage.theme.promotionImage}
        />
      )}
    </Styled.Header>
  );

  const body = (
    <Styled.Body>
      {!!discountAmount && (
        <div className={"originalPrice"}>
          {shopPackage.priceInfo.nonDiscountPrice.toLocaleString()} تومان
        </div>
      )}
      <div className={"currentPrices"}>
        <div className={"price"}>
          {shopPackage.priceInfo.price.toLocaleString()} تومان
        </div>
        <span>&nbsp;|&nbsp;</span>
        <div className={"monthlyPrice"}>
          ماهیانه {shopPackage.priceInfo.monthlyPrice.toLocaleString()} تومان
        </div>
      </div>
    </Styled.Body>
  );

  return (
    <Styled.Wrapper onClick={onClick}>
      {!!discountAmount && (
        <div className={"discount"}>
          ٪{discountAmount} تخفیف {promotionName}
        </div>
      )}
      <Card
        header={header}
        headerClassName={"shop-card-header"}
        className={isSelected ? "selected" : "deselected"}
      >
        {AutomatiRenewal ? (
          <p className="monthlyPrice">
            با انتخاب تمدید خودکار، مبلغ به‌صورت خودکار از حساب شما کسر و اشتراک
            شما تمدید می‌شه.
          </p>
        ) : (
          body
        )}
      </Card>
    </Styled.Wrapper>
  );
}

export default ShopCard;
