import styled from "styled-components";

export const Wrapper = styled.button`
  position: relative;
  width: ${props => props.fullWidth ? "100%" : 'auto'};
  border: none;
  text-align: center;
  display: inline-block;
  border-radius: ${props => props.theme.borderRadius};
  background-color: ${props => props.theme.palette.button[props.variant].background};
  color: ${props => props.theme.palette.button[props.variant].color};
  box-shadow: 0 1px 0 0.4px ${props => props.theme.palette.button[props.variant].shadow};

  &.medium{
    padding: ${props => props.theme.spacing[1]} ${props => props.theme.spacing[3]};
    font-size: 16px;
    height: 56px;
  }
  &.small{
    padding: 0.2rem 1rem;
    height: 2rem;
    font-size: 14px;
  }
  &.outlined {
    background-color: transparent;
    color: ${props => props.theme.palette.button[props.variant].background};
    box-shadow: none;
    border: solid 1px ${props => props.theme.palette.button[props.variant].background};
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    background-color: ${props => props.theme.palette.button.disabled.background};
    color: ${props => props.theme.palette.button.disabled.color};
    box-shadow: 0 1px 0 0.4px ${props => props.theme.palette.button.disabled.shadow};
    cursor: unset;
  }
  

  &.loading{
    .button-content{
     height: 0;
      overflow: hidden;
    }
  }
  .button-loading-icon {
    color: inherit;
    width: 1.3rem;
    height: 1.3rem;
  }
`;