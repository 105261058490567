import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 0.5rem 0;
  & > * {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
  }
 
`;

export const Wrapper2 = styled.div`
  & > * {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 20px;
  }
 
`;