import ShopCard from "./shopCard/ShopCard";
import React from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import "swiper/css/pagination";
import * as Styled from './styles';
import useMediaQuery from "../../../hooks/useMediaQuery";
import {device} from "../../../config/theme";
import {Pagination} from "swiper";

const ShopPackages = ({packages, selectedIndex,onSelectPackage}) => {


    const isDesktop = useMediaQuery(device.laptop);
    // const [swiper, setSwiper] = useState(null);
    //
    // useEffect(() => {
    //     if(swiper){
    //         swiper.on('slideChange', function () {
    //             console.log('slide changed');
    //         });
    //     }
    // }, [swiper]);
    return (
        <Styled.Wrapper>
            <Swiper
                slidesPerView={"auto"}
                breakpoints={{
                    1280: {
                        slidesPerView: 3,
                    },
                }}
                pagination={true}
                modules={[Pagination]}
                spaceBetween={15}
                onSwiper={() => {
                    onSelectPackage(0)
                }}
                onSlideChange={swiper => onSelectPackage(swiper.activeIndex)}
            >
                {packages.map((shopPackage,index) => (
                        <SwiperSlide key={shopPackage.id}>
                            <ShopCard shopPackage={shopPackage} isSelected={index === selectedIndex} onClick={() => onSelectPackage(index)}/>
                        </SwiperSlide>
                    )
                )}
            </Swiper>
        </Styled.Wrapper>


    )
}

export default ShopPackages;