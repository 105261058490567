import React, {useContext} from 'react';
import Typography from "../typography/Typography";
import * as Styled from "./styles";
import {AuthContext} from "../../providers/AuthProvider";
import SubscriptionIcon from "../icons/SubscriptionIcon";
import {getDayDiff} from "../../utils/date";

const SubscriptionStatus = () => {
    const {user} = useContext(AuthContext);
    const remainingDays = getDayDiff(new Date(), user.subscription);
    const subscriptionDate = remainingDays ? remainingDays >= 4 * 365 ? 'مادام العمر' : `${remainingDays} روز` : 'بدون اشتراک';

    return (
        <Styled.Wrapper>
            <SubscriptionIcon className={"subscription-icon"}/>
            &nbsp;
            <Typography variant={"bodySmall"}>وضعیت اشتراک:</Typography>
            &nbsp;
            <Typography variant={"bodySmall"} className={"remaining"}>{user.hasActiveAutoRenewal ? "تمدید خودکار" : subscriptionDate}</Typography>
            &nbsp;
            { remainingDays > 0 ?
            <span className={"pro-label"}>{user.subscriptionType === "pro" ? "پیشرفته": "پایه"}</span>
            :
            <></>
            }
        </Styled.Wrapper>
    );
};

export default SubscriptionStatus;