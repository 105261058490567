import styled from "styled-components";
import {device} from "../../config/theme";

export const Wrapper = styled.div`
  margin: auto;
  width: 100%;
  padding: 0 0.75rem;
  max-width: 800px;
  @media (${device.laptop}){
    padding: 0 12rem;
    max-width: 1500px;
  }
`;

export const Wrapper2 = styled.div`
  margin: auto;
  width: 100%;
  padding: 0 0.1rem;
  max-width: 800px;
  @media (${device.laptop}){
    padding: 0 2rem;
    max-width: 1500px;
  }
`;