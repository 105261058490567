import React from 'react';

const UnlockIcon = (props) => {
    return (
        <svg {...props} width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_dii_485_5169)">
                <g filter="url(#filter1_dii_485_5169)">
                    <path d="M16.9119 4L19.4885 4C22.5094 4 24.1293 4.41772 25.596 5.2021C27.0627 5.98648 28.2137 7.13753 28.9981 8.60419C29.7825 10.0709 30.2002 11.6908 30.2002 14.7117V17.2883C30.2002 20.3092 29.7825 21.9291 28.9981 23.3958C28.2137 24.8625 27.0627 26.0135 25.596 26.7979C24.1293 27.5823 22.5094 28 19.4885 28H16.9119C13.891 28 12.2711 27.5823 10.8044 26.7979C9.33772 26.0135 8.18667 24.8625 7.40229 23.3958C6.61791 21.9291 6.2002 20.3092 6.2002 17.2883V14.7117C6.2002 11.6908 6.61791 10.0709 7.40229 8.60419C8.18667 7.13753 9.33772 5.98648 10.8044 5.2021C12.2711 4.41772 13.891 4 16.9119 4Z" fill="#29CC88"/>
                </g>
                <path d="M15.3428 15.8839C15.3428 15.8839 15.3428 12.5432 15.3428 11.8839C15.3428 9.85601 16.8506 9 18.1999 9C19.5493 9 21.0571 9.84774 21.0571 11.8839C21.0571 12.0601 21.0571 12.4485 21.0571 12.4485" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M14.2002 18.9632C14.2002 16.7018 15.9362 14.9258 18.2002 14.9258C20.4642 14.9258 22.2002 16.6563 22.2002 18.9632C22.2002 21.2702 20.4497 23.0007 18.2002 23.0007C15.9506 23.0007 14.2002 21.2246 14.2002 18.9632Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M17.5439 18.9422C17.5439 18.5458 17.8483 18.2344 18.2452 18.2344C18.6422 18.2344 18.9465 18.5378 18.9465 18.9422C18.9465 19.3467 18.6396 19.6501 18.2452 19.6501C17.8508 19.6501 17.5439 19.3387 17.5439 18.9422Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <filter id="filter0_dii_485_5169" x="0.200195" y="0" width="36" height="36" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="2"/>
                    <feGaussianBlur stdDeviation="3"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_485_5169"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_485_5169" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="-0.5"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"/>
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_485_5169"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="0.5"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"/>
                    <feBlend mode="normal" in2="effect2_innerShadow_485_5169" result="effect3_innerShadow_485_5169"/>
                </filter>
                <filter id="filter1_dii_485_5169" x="0.200195" y="0" width="36" height="36" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="2"/>
                    <feGaussianBlur stdDeviation="3"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_485_5169"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_485_5169" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="-0.5"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"/>
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_485_5169"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="0.5"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"/>
                    <feBlend mode="normal" in2="effect2_innerShadow_485_5169" result="effect3_innerShadow_485_5169"/>
                </filter>
            </defs>
        </svg>

    );
};

export default UnlockIcon;