import * as Yup from 'yup';
import {toEnglishNumber} from "./number";

const validation = function (){
    Yup.addMethod(Yup.string,"phoneNumber",function(message){

        const phoneNumberRegex = /^(\+98|0)?9\d{9}$/;
        return this.test('phone-number',message,function(value){
            return phoneNumberRegex.test(toEnglishNumber(value));
        })
    })

    return Yup;
}();

export default validation;