import styled from "styled-components";
import {device} from "../../config/theme";
import character from '../../assets/images/character.svg';
import Typography from "../typography/Typography";
import BackIcon from "../icons/BackIcon";

export const Wrapper = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.theme.palette.publicBackground};
  & > * {
    @media (${device.laptop}) {
      flex-basis: 50%;
    }
  }
`;

export const Image = styled.div`
  display: none;
  @media (${device.laptop}) {
    display: block;
    background-color: #29CC88;
    background-image: url(${character});
    background-repeat: no-repeat;
    background-position: center center;
  }
`;

export const Main = styled.main`
  width: 100%;
  max-width: 500px;
  position: relative;
  margin:0 auto;
  padding: 1rem;
  @media (${device.laptop}) {
    padding: 3rem;
    margin: auto;
  }
`;

export const BackButton = styled(BackIcon)`
  margin-bottom: ${props => props.theme.spacing[2]};
  cursor: pointer;
  width: 10px;
  height: 10px;
  color: ${props => props.theme.palette.button.primary.background};
`;

export const Title = styled(Typography)`
  margin-bottom: ${props => props.theme.spacing[2]};
`;
export const Description = styled(Typography)`
  margin-bottom: ${props => props.theme.spacing[4]};
  color: ${props => props.theme.palette.text.secondary};
`;