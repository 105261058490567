import React from 'react';
import * as Styled from './styles';

const PublicLayout = ({children, title, description, onGoBack}) => {
    return (
        <Styled.Wrapper>
            <Styled.Main>
                {onGoBack && <Styled.BackButton onClick={onGoBack}/>}
                <Styled.Title variant={"heading2"}>{title}</Styled.Title>
                {description && <Styled.Description variant={"bodyMedium"}>{description}</Styled.Description>}
                {children}
            </Styled.Main>
            <Styled.Image/>
        </Styled.Wrapper>
    );
};

export default PublicLayout;