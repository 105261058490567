// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/font/Vazirmatn-FD-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/font/Vazirmatn-FD-Bold.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n  @font-face {\n    font-family: \"vazir\";\n    src:local('vazir'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\");\n    font-style: normal;\n    font-weight: normal;\n    font-display: block;\n  }\n  @font-face {\n    font-family: \"vazir\";\n    src:local('vazir'), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\");\n    font-style: normal;\n    font-weight: bold;\n    font-display: block;\n  }\n", "",{"version":3,"sources":["webpack://./src/styles/font.css"],"names":[],"mappings":";EACE;IACE,oBAAoB;IACpB,2EAAoF;IACpF,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;EACrB;EACA;IACE,oBAAoB;IACpB,2EAAiF;IACjF,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;EACrB","sourcesContent":["\n  @font-face {\n    font-family: \"vazir\";\n    src:local('vazir'), url(\"../assets/font/Vazirmatn-FD-Regular.woff2\") format(\"woff2\");\n    font-style: normal;\n    font-weight: normal;\n    font-display: block;\n  }\n  @font-face {\n    font-family: \"vazir\";\n    src:local('vazir'), url(\"../assets/font/Vazirmatn-FD-Bold.woff2\") format(\"woff2\");\n    font-style: normal;\n    font-weight: bold;\n    font-display: block;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
