import storage from "../config/storage";
import api from "../config/api";
import {toEnglishNumber} from "../utils/number";

const authService = {
    getCode(phoneNumber) {
        return api({
            url: "/requestCode",
            method: "post",
            data: {phoneNumber: toEnglishNumber(phoneNumber), market: "APP_STORE"}
        })
    },
    login(credentials) {
        return api({
            url: "/login", method: "post", data: {
                phoneNumber: toEnglishNumber(credentials.phoneNumber),
                code:credentials.code,
                market: "APP_STORE"
            }, retry: false
        })
    },
    loginGOOGLE(credentials) {
        return api({
            url: "/tpl/webShop/google", method: "post", data: {
                token:credentials.token,
                market: "APP_STORE",
                appCode: credentials.appCode,
                version: credentials.version,
            }, retry: false
        })
    },
    getCurrentUser() {
        return api({url: "/user/profile/pwa"})
    },
    updateUser(user) {
        return api({url: "/user/profile", method: 'put', data:user});
    },
    refreshToken() {
        return api({
            url: '/authentication/refresh_token',
            headers: {'Authorization': storage.getRefreshToken()},
            retry: false
        })
    },
    completeSignUp(user) {
        return api({url: '/completeSignup', method: 'post', data: user})
    },
    appOpened(){
        return api({url:'/user/app/opened',method:'post',data:{market:'APP_STORE',versionName:'2',shopVersion:'2.1'}})
    }

}

export default authService;
