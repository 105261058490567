import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.palette.text.secondary};
  white-space: nowrap;

  .remaining {
    font-weight: 600;
    color: ${props => props.theme.palette.text.primary};
  }
  
  .pro-label{
    color: ${props => props.theme.palette.text.highlight};
  }
  
  .subscription-icon{
    color: ${props => props.theme.palette.text.highlight};
    width: 20px;
    height: 20px;
  }
`;