import React, {useEffect, useRef} from 'react';
import * as Styled from './styles';
import * as ReactDOM from "react-dom";
import Backdrop from "../backdrop/Backdrop";
import useLockScroll from "../../hooks/useLockScroll";

const Modal = ({title, children, onClose, isOpen}) => {

    const ref = useRef(null);
    const {onLock,onUnlock} = useLockScroll(ref);

    useEffect(() => {
        if(isOpen){
           onLock()
        }else{
            onUnlock();
        }
    },[isOpen])

    return (
        ReactDOM.createPortal(<div ref={ref}>
            <Backdrop isOpen={isOpen} onClick={onClose}/>
            <Styled.Modal className={isOpen ? "opened" : "closed"}>
                <Styled.Header>
                    <div>{title}</div>
                    <Styled.CloseButton onClick={onClose}/>
                </Styled.Header>
                <hr/>
                <Styled.Body>
                    {children}
                </Styled.Body>
            </Styled.Modal>
        </div>, document.getElementById("modal-root"))

    );
};

export default Modal;