import styled from "styled-components";
import {device} from "../../config/theme";
import CloseIcon from "../icons/CloseIcon";


export const Modal = styled.div`
  z-index: 101;
  background-color: ${props => props.theme.palette.foreground};
  position: fixed;
  max-width: 600px;
  width: 100%;
  @media(${device.mobile}){
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    transition: transform 200ms ease-in-out;
    bottom: 0;
    left: 50%;
    &.closed{
      transform: translate(-50%,100%);
    }
    &.opened{
      transform: translate(-50%,0);
    }
  }
  @media(${device.laptop}){
    border-radius: 20px;
    left: 50%;
    top: 50%;
    bottom: unset;
    overflow: hidden;
    transition: opacity 250ms ease-in-out;
    transform-origin: center;
    &.opened{
      transform: scale(1) translate(-50%,-50%);
      opacity: 1;
    }
    &.closed{
      transform: scale(0) translate(-50%,-50%);
      opacity: 0;
    }
  }
 
  hr{
    height: 0;
    border: 0;
    border-top: 1px solid ${props => props.theme.palette.divider};
    width: 95%;
    margin: auto;
  }
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  color: ${props => props.theme.palette.text.secondary};
`;
export const Body = styled.div`
  padding: 1rem;
`;
export const CloseButton = styled(CloseIcon)`
  color: ${props => props.theme.palette.text.highlight};
  cursor: pointer;
  width: 30px;
  height: 30px;
`;