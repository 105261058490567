import React from "react";
import * as Styled from "./styles";
import Loading from "../loading/Loading";

const Button = ({
  children,
  onClick,
  variant = "primary",
  isLoading,
  disabled = isLoading,
  fullWidth = false,
  size = "medium",
  className = "",
  type = "button",
  outlined = false,
}) => {
  const getClassName = () => {
    return (
      className +
      (outlined ? " outlined" : "") +
      (isLoading ? " loading" : "") +
      (size === "medium" ? " medium" : " small")
    );
  };
  return (
    <Styled.Wrapper
      onClick={onClick}
      disabled={disabled || isLoading}
      variant={variant}
      fullWidth={fullWidth}
      size={size}
      className={getClassName()}
      type={type}
    >
      {isLoading && <Loading className={"button-loading-icon"} />}
      <div className={"button-content"}>{children}</div>
    </Styled.Wrapper>
  );
};

export default Button;
