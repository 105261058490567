import styled from "styled-components";

export const Wrapper = styled.div`
  padding-top:1rem;
  cursor: pointer;
  position: relative;
  .title_box{
    display:flex;
    margin-top:1rem;
  }
  .shop-card-header{
    padding: 23px;
  }
  .selected {
    border-color: ${props => props.theme.palette.button.primary.background};
    border-width: 2px;
  }
  .deselected{
    border-width: 2px;
  }
  
  .discount{
    position: absolute;
    width: fit-content;
    white-space: nowrap;
    background-color: ${props => props.theme.palette.button.primary.background};
    border-radius: 25px;
    color: ${props => props.theme.palette.button.primary.color};;
    padding: 0.2rem 1.5rem;
    left: 50%;
    transform: translate(-50%,-50%);
    
  }
`;
export const Header = styled.div`

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  .main{
    .title{
      font-size: 20px;
      font-weight: bold;
    }
    //padding-top: 0.5rem;
    .profit {
      color: ${props => props.theme.palette.text.secondary};
      font-weight: 500;
      //margin-top: 10px;
    }
  }
  .promotion-image{
    width: 50px;
    height: 50px;
  }


`;

export const Body = styled.div`

  padding: 1.5rem 0;
  height: 54px;
  .originalPrice {
    position: absolute;
    color: ${props => props.theme.palette.text.tertiary};
    text-decoration: line-through;
    text-decoration-color: ${props => props.theme.palette.text.tertiary};
    font-size: 14px;
    bottom: 3rem;
  }
  
  .currentPrices{
    display: flex;
    white-space: nowrap;
    color: ${props => props.theme.palette.text.secondary};
    .monthlyPrice {
      font-weight: 600;
      color: ${props => props.theme.palette.text.primary};
    }
    
  }

  
`;