const storage = {
    setToken(token){
        localStorage.setItem('Authorization',token)
    },
    getToken(){
        return localStorage.getItem('Authorization');
    },
    getIdentity(){
        return localStorage.getItem('identity');
    },
    setRefreshToken(refreshToken){
        localStorage.setItem('refreshToken', refreshToken);
    },
    getRefreshToken() {
        return localStorage.getItem('refreshToken');
    },
    clear(){
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('Authorization');
    },
    hasToken(){
       return !!storage.getToken()
    },
    getMarket(){
        return localStorage.getItem("market");
    },
    setMarket(market){
        localStorage.setItem("market", market);
    },
    setVersion(version) {
        localStorage.setItem("version", version);
    },
    setIdentity(identity) {
        localStorage.setItem("identity", identity);
    },
    getVersion() {
        return localStorage.getItem('version');
    }
}
export default storage;