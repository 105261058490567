import styled from "styled-components";

export const Wrapper = styled.div`
 
  margin-top: 24px;
  border-top:0.5px solid ${props => props.theme.palette.divider};
  padding: 24px 0;
  .shop-table-header{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 20px;
    color :${props => props.theme.palette.text.secondary}
  }
  .shop-table-row:nth-child(2n){
    background: ${props => props.theme.palette.background};
  } 
  .shop-table-row:nth-child(2n-1){
    background: ${props => props.theme.palette.foreground};
  }
  .shop-table-row{
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
  }
  .shop-table-more-button {
    font-size: 12px;
    color : ${props => props.theme.palette.text.highlight};
    margin-left: 24px;
    cursor: pointer;
  }
  .shop-table-content{
    font-size: 15px;
    color : ${props => props.theme.palette.text.secondary};
    margin-right: 24px;
  }
`;
