import * as Styled from "./styles";
import React from "react";
import Typography from "../../../../components/typography/Typography";
import Card from "../../../../components/card/Card";
import AutoImage from "../../../../assets/images/direct.svg";
import moment from "moment-jalaali";

function AutoRenewCard({ expirationTime }) {
    const header = (
        <Styled.Header>
            <div className="main">
                <div className="title_box">
                    <img src={AutoImage} className={"promotion-image"} />
                    <div>
                        <p>یک‌ماهه تمدید خودکار</p>
                        <p>تاریخ تمدید بعدی: {moment(expirationTime).locale("fa").format("jYYYY/jMM/jDD")}</p>
                    </div>
                </div>
            </div>
        </Styled.Header>
    );

    return (
        <Styled.Wrapper>
            <Card
                header={header}
                headerClassName={"shop-card-header"}
                className={"selected"}
            >
                <p className="monthlyPrice">
                    با انتخاب تمدید خودکار، مبلغ به‌صورت خودکار از حساب شما کسر و اشتراک
                    شما تمدید می‌شه.
                </p>
            </Card>
        </Styled.Wrapper>
    );
}

export default AutoRenewCard;
