import React, { useContext, useState } from "react";
import { AuthContext } from "../../providers/AuthProvider";
import { Formik } from "formik";
import Input from "../../components/inputs/Input/Input";
import validation from "../../utils/validation";
import PublicLayout from "../../components/publicLayout/PublicLayout";
import * as Styled from "./styles";
import Button from "../../components/button/Button";
import ResendOtpButton from "./ResendOtpButton/ResendOtpButton";
import authService from "../../services/authService";
import { useNavigate } from "react-router-dom";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { toast } from "react-toastify";
import storage from "../../config/storage";

const Login = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const [codeLoading, setCodeLoading] = useState(false);
  const [step, setStep] = useState("phoneNumber");

  const onGetCode = (phoneNumber) => {
    setCodeLoading(true);
    authService.getCode(phoneNumber).then(() => {
      setCodeLoading(false);
      setStep("code");
    });
  };

  const getStep = (step) => {
    switch (step) {
      case "phoneNumber":
        return {
          form: () => (
            <Input
              type={"tel"}
              name={"phoneNumber"}
              placeholder={"مثال: ۰۹۱۲۳۴۵۶۷۸۹"}
              label={"شماره همراه"}
            />
          ),
          validationSchema: validation.object({
            phoneNumber: validation
              .string()
              .phoneNumber("شماره صحیح نمیباشد")
              .required("شماره همراه الزامی است"),
          }),
          title: () => validateEmail(identity) ? "برای مشاهده فروشگاه لطفا از طریق ورود با گوگل وارد شوید." : "شماره همراه خود را وارد کنید.",
          description: () => validateEmail(identity) ? "  " : "کد تایید به این شماره ارسال خواهد شد.",
          onSubmit: (values) => onGetCode(values.phoneNumber),
          onGoBack: null,
        };
      case "code":
        return {
          form: (formik) => (
            <>
              {/* <Otp name={"code"} label={"کد"}/> */}
              <Input type={"tel"} name={"code"} placeholder={"مثال: 1234"}
                label={"کد"} autocomplete={"one-time-code"} />
              <ResendOtpButton
                onResend={() => onGetCode(formik.values.phoneNumber)}
              />
            </>
          ),
          validationSchema: validation.object({
            code: validation
              .string()
              .length(4, "کد ۴رقمی است")
              .required("کد را وارد کنید"),
          }),
          title: () => "کد فعالسازی",
          description: (formik) =>
            `کد تایید ارسال شده به شماره ${formik.values.phoneNumber} را وارد کنید.`,
          onSubmit: (values, formik) =>
            auth
              .login(values)
              .then(() => navigate("/"))
              .catch(() =>
                formik.setErrors({
                  code: "کد وارد شده اشتباه است",
                })
              ),
          onGoBack: (formik) => {
            setStep("phoneNumber");
            formik.setFieldValue("code", "");
          },
        };
      default:
        return;
    }
  };

  const isLoading = auth.loginLoading || codeLoading;

  const { title, description, form, validationSchema, onSubmit, onGoBack } =

    getStep(step);

  const handleLogin = async (credentialResponse) => {
    auth.loginGoogle({
      token: credentialResponse.credential,
    })
      .then(() => navigate("/"))
      .catch(() => {
        toast.error("لطفا دوباره امتحان کنید.");
      });
  };

  const identity = storage.getIdentity();

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <Formik
      initialValues={{
        phoneNumber: "",
        code: "",
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <PublicLayout
          title={title(formik)}
          description={description(formik)}
          onGoBack={onGoBack ? () => onGoBack(formik) : null}
        >
          <Styled.Form>
            {validateEmail(identity) ?
              <div className="google">
                <GoogleOAuthProvider
                  clientId="1004851415832-1uje8hsphvqh3m8os84i5g1494cej20m.apps.googleusercontent.com"
                >
                  <GoogleLogin
                    onSuccess={handleLogin}
                    shape="circle"
                    size="large"
                    text="signin_with"
                    logo_alignment="left"
                    width={1000}
                  //   onError={() => {
                  //     toast.warn("مشکلی پیش آمده")
                  //   }}
                  />
                </GoogleOAuthProvider>
              </div>
              : 
              <>
              {form(formik)}
              <Button
              className={"submitButton"}
              isLoading={isLoading}
              fullWidth
              type={"submit"}
            >
              {isLoading
                ? "loading"
                : step === "phoneNumber"
                  ? "دریافت کد"
                  : "ورود"}
            </Button>
            </>
            }
          </Styled.Form>
        </PublicLayout>
      )}
    </Formik>
  );
};

export default Login;
