import api from "../config/api";
import storage from "../config/storage";

const subscriptionService = {
    getPackages() {
        return api({url: '/shop/v2/shop-package/client', params: {market: storage.getMarket() || 'APP_STORE', shopVersion: 2.1}})
    },
    getDiscountPackages({discountCode}) {
        return api({
            url: '/user/discount_code',
            method: 'PUT',
            data: {code: discountCode, market: storage.getMarket() || 'APP_STORE', shopVersion: 2.1}
        });
    },
    getTransaction(transactionId) {
        return api({url: `/shop/v2/initiate-payment/${transactionId}`, method: 'get'})
    },
    getVandarBanks() {
        return api({url: `/direct-debit/vandar/banks`, method: 'get'})
    },
    getVandarGatewayURL(bankCode, cardId, transactionToken) {
        return api({url: `/direct-debit/subscription/requests?bankCode=${bankCode}&packId=${cardId}&token=${transactionToken}`, method: 'get'})
    }
}

export default subscriptionService;